.login-popup {
    width: 500px;
    height: min-content;
    position: absolute;
    margin: 0 auto;
    padding: 0;
    z-index: 100;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.login-popup .card {
    border: 2px solid #2a2829;
}

.login-popup .card-header {
    background-color: #2a2829;
    border: none;
    border-radius: 0 !important;
    color: #fffeff;
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
}

.login-body {
    position: relative;
    padding: 1.25rem 75px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.login-body button, .login-button button:hover, .login-button button:active, .login-button button:link, .login-button button:focus, .login-button button:visited {
    border: none;
    border-radius: 20px;
    background-color: #9F283A;
    outline: none;
    transition: transform 80ms ease-in;
    text-decoration: none;
    font-weight: 800;
    padding: 5px 30px;
    margin: 0;
}

.login-field {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-field input {
    outline: none;
    border: none;
    background-color: #eee;
    padding: 12px 15px;
    margin: 8px 0;
}

.grey-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 5;
    opacity: 0.4;
}