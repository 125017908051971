.page {
    position: relative;
    width: 100%;
    height: 100%;
}

.deactivated {
    user-select: none;
    pointer-events: none;
    -webkit-filter: blur(3px);
    filter: blur(3px);
}

.content-container {
    position: relative;
    height: 100%;
    max-width: 1000px;
    margin: 30px auto;
    padding: 0;
}

.content-container nav ul li.active a {
    color: #9F283A;
}

main {
}

hr {
    margin: 5px 0 !important;
}

.card {
    margin: 15px 0;
    border-radius: 0;
}

.card-header {
    font-weight: 800;
}

.content-container h5.card-header {
    color: #e4e3e3;
    text-transform: capitalize;
    border: none;
    background-color: #2a2829;
}

.content-container .card-body {
    min-height: 100px;
    padding: 0;
}

.content-list {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    min-height: 400px;
}

.content-list .add-icon-wrapper {
    height: 30px;
    display: flex;
    justify-content: right;
    align-items: center;
}

.content-list .add-icon-wrapper > svg {
    position: absolute;
    margin-right: 10px;
}

.content-list .add-icon-wrapper > svg:hover {
    color: #4DBD33;
    cursor: pointer !important;
}

.content-list tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.03);
}

.content-list td {
    text-align: left;
    padding: 5px;
}

.content-list td span {
    color: #2a2829;
}

.content-list td.icon-column {
    text-align: center;
}

.content-list tr:hover {
    background-color: #e4e3e3 !important;
}

.content-list tr.hover-item td svg, td.icon-column button {
    display: none;
}

.content-list tr.hover-item:hover td svg, tr.active-hover-item td svg, tr.hover-item:hover td.icon-column button {
    display: inherit;
}

.content-list tr.active-hover-item {
    background-color: #9F283A !important;
}

.content-list tr.active-hover-item input {
    outline: none;
    border: none;
    width: 95%;
    padding: 0 10px;
}

.content-list tr.active-hover-item input::-moz-selection {
    color: white;
    background-color: #9F283A;
}

.content-list svg.edit-icon:hover {
    color: #0099CC;
    cursor: pointer !important;
}

.content-list tr.active-hover-item svg.edit-icon:hover, .content-list tr.active-hover-item svg.delete-icon:hover {
    color: white;
}

.content-list svg.delete-icon:hover {
    color: #9F283A;
    cursor: pointer !important;
}

.content-list .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #7f7d7e;
}

.content-list td.two-inputs {
    display: flex;
    align-items: center;
}

.content-list td.two-inputs input {
    width: 47%;
}

.content-list td.two-inputs span:nth-child(2) {
    margin: 0 10px;
}

.content-list td.two-inputs div[class$="container"] {
    width: 47%;
}

.content-list td.icon-column button.edit-user-button {
    background-color: #0099CC;
    border: none;
    border-radius: 0;
    font-size: 14px;
}

.content-list td.icon-column button.save-user-button {
    display: inherit;
    border: none;
    border-radius: 0;
    font-size: 14px;
    background-color: #2a2829;
}

.content-list svg.admin-icon {
    display: inline !important;
}

.content-list tr.user-row {
    height: 60px;
    font-size: 14px;
}

.content-list tr.user-row button {
    width: max-content;
    border: none;
    border-radius: 0;
    outline: none;
    background-color: #0099CC;
}

.user-body {
    min-height: 300px !important;
    max-height: 600px !important;
}

.content-list .new-user-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.content-list .new-user-box input {
    border: none;
    border-bottom: 2px solid #0099CC;
    border-radius: 0;
    outline: none;
    width: 150px;
}

.content-list .new-user-box button {
    background-color: #0099CC;
    border: none;
    border-radius: 0;
    font-size: 14px;
}

.content-list .container .row {
    min-height: 250px;
}

.content-list .user-list {
    overflow: hidden;
}

.content-list .hover-item td {
    padding: 10px;
}

.content-list tr.file td span {
    margin: 0 5px
}

.content-list .user-list .user-row svg {
    margin-left: 10px;
}

.content-list .user-edit {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    padding: 10px;
    position: relative;
}

.content-list .user-edit hr {
    margin-top: 0 !important;
}

.content-list .user-edit .reset-password-button, .content-list .user-edit .add-permission-button {
    background-color: #0099CC;
    border: none;
    border-radius: 0;
    font-size: 14px;
    width: 100%;
    margin-bottom: 10px;
}

.content-list .user-edit .add-permission-button {
    margin-top: 10px;
}

.content-list .user-edit .permission-resources {
    font-size: 14px;
}

.content-list .user-edit .permission-timespan {
    display: grid;
    font-size: 12px;
    text-align: center;
}

.content-list .user-edit .permission-resources ul {
    list-style: square inside;
}

.content-list .hover-item svg.resource-type-icon {
    display: inline !important;
}

.content-list .event-list-item .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-list .event-list-item .card-header svg.fa-pencil-alt {
    margin: 0 10px
}

.content-list .event-list-item .event-details div.row {
    min-height: max-content;
    padding: 5px;
    text-align: left;
}

.content-list .create-event-button {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-category {
    width: 200px;
}

.content-list .create-event-button button, .create-event-modal button.btn-primary, .create-permission-button button {
    background-color: #0099CC;
    border: none;
    border-radius: 0;
    font-size: 14px;
}

.create-event-modal button.btn-secondary {
    border: none;
    border-radius: 0;
    font-size: 14px;
}

.create-event-modal div.row {
    margin: 10px 0;
    position: relative;
}

.create-event-modal div.row div[class^="col"] {
    display: flex;
    justify-content: left;
    align-items: center;
}

.create-event-modal div[class$="container"] {
    width: 100%;
}

.create-event-modal span.separator {
    margin: 0 10px;
}

.create-event-modal span.react-numeric-input {
    margin-left: 10px;
    width: 100px;
}

.create-event-modal .react-datepicker-wrapper input {
    padding: 0 5px;
    text-align: center;
}

.create-event-modal input {
    border: none;
    border-bottom: 2px solid #0099CC;
    border-radius: 0;
    outline: none;
    width: 100%;
}

.create-event-button input, .create-permission-button input {
    border: none;
    border-bottom: 2px solid #0099CC;
    border-radius: 0;
    outline: none;
    width: 100%;
}

.create-event-modal input[type="checkbox"] {
    width: min-content;
}

.create-event-modal textarea {
    width: 100%;
    border: 1px solid #0099CC;
}

.file-upload {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.file-upload.container .row {
    width: 100%;
}

.file-upload.container .row div[class^="col"] {
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-upload input {
    border: none;
    border-bottom: 2px solid #0099CC;
    border-radius: 0;
    outline: none;
    width: 100%;
}

.file-upload input[type="file"] {
    display: none;
}

.file-upload button {
    background-color: #0099CC;
    border: none;
    border-radius: 0;
}

.file-upload span.filename {
    color: #7f7d7e;
    font-size: 12px;
}

.annotations, .enrolled-users {
    display: grid;
}

.annotations svg.delete-icon, .enrolled-users svg.delete-icon, .permission-list-item svg.delete-icon {
    margin-left: 5px;
    display: none;
}

.annotations li:hover svg.delete-icon, .enrolled-users li:hover svg.delete-icon, .permission-list-item div.card-header:hover svg.delete-icon {
    display: initial;
}

.permission-range {
    display: flex;
    justify-content: center;
}

.permission-range span {
    margin: 0 10px
}

.create-permission-modal .react-datepicker-wrapper input {
    padding: 0 5px;
    text-align: center;
    width: calc(100% - 5px);
}

.create-permission-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

div.row > div > input {
    width: 100%;
}

div.datepicker-input-box {
    width: 100% ;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.datepicker-input-box .delete-icon {
    display: initial;
}

div.timespan {
    display: flex;
    align-items: center;
}

div.permission-list-item div.card-body {
    min-height: 300px;
}

li.editable-annotation {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delete-permission {
    padding: 0 5px
}

.delete-permission svg.delete-icon:hover {
    cursor: pointer !important;
}

.permission-list-item .card-header {
    display: flex;
    justify-content: space-between;
}

.permission-list-item .card-header svg {
    margin-left: 5px
}

.card-header input, .user-row input {
    margin-right: 5px;
}

.permission-list-item .row {
    padding: 5px 0;
}

.permission-list-item svg.delete-icon {
    margin: 0 5px;
}

.permission-list-item svg.resource-type-icon {
    margin-right: 5px;
}

.search {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 50%;
}

.search-input {
    width: 100%;
}

svg.add-icon {
    color: #4DBD33;
    float: right;
}

svg.add-icon:hover, svg.edit-icon:hover, svg.delete-icon:hover {
    cursor: pointer !important;
}

svg.edit-icon:hover {
    color: #0099CC !important;
}

svg.delete-icon:hover {
    color: #9F283A !important;
}