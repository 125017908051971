header {
    width: 100%;
    height: 50px;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

header span {
    font-size: 20px;
    font-weight: 800;
    margin: 0 25px;
}

header div.header-loading {
    position: absolute;
    right: -25px;
}

header div.header-loading svg path {
    stroke: white;
}

header div.sign-out {
    position: absolute;
    left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

header div.sign-out svg.sign-out-icon {
    color: white;
    font-size: 20px;
}

header div.sign-out svg.sign-out-icon:hover {
    cursor: pointer !important;
}
