html, body, div, p, a, span, label, h1, h2, h3 {
    font-feature-settings: "kern", "liga";
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
}

h1, h2, h3 {
    font-weight: 800;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    padding: 5px;
    margin: 0;
}

label {
    margin: 0;
}

a {
    color: #2a2829;
    font-size: 18px;
    font-weight: 800;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: #9F283A;
}

a:active {
    text-decoration: none;
}

table {
    width: 100%;
}

input {
    color: #2a2829;
}

svg {
    cursor: default !important;
}

button {
    outline: 0;
    box-shadow: none !important;
}

.primary {
    color: #9F283A;
}

.text-primary {
    color: #7f7d7e;
}

.text-secondary {
    color: #2a2829;
}

.text-light {
    color: #fffeff;
}

.background-primary {
    background-color: #fffeff;
}

.background-secondary {
    background-color: #e4e3e3;
}

.background-colorful {
    background-color: #9F283A;
}

.centered {
    margin: 0 auto;
    text-align: center;
}

@media (min-width: 1400px) {
    .modal-dialog {
        max-width: 1000px !important;
    }
}
